.Preloader {
  background-color: #171717;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100000;
  -webkit-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  transition: all 0.1s ease;
  background-size: cover;
  background-position: bottom center;

  &.loaded {
    -webkit-animation-name: slideOut;
    animation-name: slideOut;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }

  .Preloader-logo {

    &__icon {
      position: relative;

      &::after {
          background-color: #fff;
          bottom: -8px;
          content: "";
          height: 2px;
          left: 50%;
          position: absolute;
          width: 0;
          -webkit-animation-name: leftToRight;
          animation-name: leftToRight;
          -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
          -webkit-animation-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
          animation-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
          -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
          -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
          -webkit-animation-direction: alternate;
          animation-direction: alternate;
          -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          transform: translateX(-50%);
          -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
          -webkit-transition: 1s 0.2s cubic-bezier(0.33, 1, 0.68, 1);
          -o-transition: 1s 0.2s cubic-bezier(0.33, 1, 0.68, 1);
          transition: 1s 0.2s cubic-bezier(0.33, 1, 0.68, 1);
      }

      img {
        width: 25vw;
        min-width: 300px;
        max-width: 500px;
        margin-bottom: 5rem;
      }
    }

  }

  @-webkit-keyframes leftToRight{from{width:0}to{width:100%}}
  @keyframes leftToRight{from{width:0}to{width:100%}}
  @-webkit-keyframes slideOut{from{-webkit-transform:translateY(0);transform:translateY(0)}to{-webkit-transform:translateY(-100%);transform:translateY(-100%)}}
  @keyframes slideOut{from{-webkit-transform:translateY(0);transform:translateY(0)}to{-webkit-transform:translateY(-100%);transform:translateY(-100%)}}
}
