@import url('https://fonts.googleapis.com/css2?family=Babylonica&family=El+Messiri:wght@700&family=Urbanist:wght@400;700;800;900&display=swap');
:root {
  --ash:#5a564f;
  --grey:#847f7e;
  --winter:#c2cad2;
  --green:#969c7f;
  --green-lighter:#7f9c83;
  --rose:#b58a8a;
  --rose-lighter:#c35555;
  --sandstone:#d7cbb0;
  --white:#fff;
  --black:#272727;
  --lilac:#7f8090;
  --clay:#b79876;
  --bodycolor:#272727;
  --dark:#11171B;
  --dark-dandelion: #59564E;
}
html {
  scroll-behavior: smooth
}

body {
  color: var(--bodycolor);
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.75;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

::-moz-selection {
  background-color: var(--rose);
  color: var(--white);
}
::selection {
  background-color: var(--rose);
  color: var(--white);
}

small {
  font-size: .875rem;
}

.signature {
  font-family: 'Babylonica', cursive;
  font-size:4rem;
}

.header-tagline {
  font-family: "El Messiri", sans-serif;
  font-size: 5rem;
  font-weight: 800;


  @media (max-width: 767.98px) {
   font-size:3rem;
  }

}
.header-text {
  font-family: "Urbanist", sans-serif;
  font-size: 2.2rem;
  font-weight: 400;
  text-transform: initial;

  @media (max-width: 767.98px) {
    font-size:1.5rem;
  }

}
.btn {
  padding:10px 70px;
  border-radius:30px;
  font-size:1.2rem;
  transition: all .35s cubic-bezier(.25,.8,.25,1);

  &:hover {
    //font-weight:700;
    //box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    box-shadow: 0px 3px 4px rgb(0 0 0 / 25%), 5px 6px 10px rgb(0 0 0 / 22%);
  }

  &.btn-white {
    color: var(--black);
    background-color: var(--white);
    border-color: var(--white);


  }

  &.btn-outline-white {
    color: var(--white);
    border-color: var(--white);

    &:hover {
      color: var(--black);
      background-color: var(--white);
      border-color: var(--white);
    }

    &:active {
      color: #fff;
      background-color: var(--white);
      border-color: var(--white);
    }

    &:focus {
      box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 50%);
    }
  }

}

@for $i from 1 through 6 {
  h#{7-$i} {
    //font-size: #{$i}rem;
    font-size: $i * .5rem;
    font-family: "El Messiri", sans-serif;
  }
}

.btn-group-lg>.btn, .btn-lg {
  padding: 0.9rem 4rem;
  border-radius: 2rem;
  font-size: 1.25rem;
}


/*
 * Sections
 */
section {
  position: relative;
  padding: 3rem 0;

  @media (max-width: 767.98px) {
    h2 {
      font-size:2.1rem;
    }
  }

}

.section-clay {
  background: var(--clay);
  color:#fff;
}

.section-grey {
  background: #eee;
}

.section-mint {
  background: #d1dcdb;
}

.section-sand {
  /*background: #f1e9e3;*/
  background: var(--sandstone);
  color:#fff;
}

.section-lilac {
  background:var(--lilac);
  color:#fff;
}

.section-ash {
  background:var(--ash);
  color:#fff;
}

.section-rose {
  background:var(--rose);
  color:#fff;
}

.section-green {
  background:var(--green);
  color:#fff;
}

.section-dark {
  background:var(--dark);
  color:#fff;
}

.section-dark-dandelion {
  background:var(--dark-dandelion);
  color:#fff;
}


.dandelion-bg {
  z-index: 0;


  &:after {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-image: url('/assets/TreeDwellers_Silhouettes-01-tl.webp'),url('/assets/TreeDwellers_Silhouettes-01.webp');
    background-repeat: no-repeat;
    background-position: top left, bottom right;
    background-size: contain;
    @media (max-width: 767.98px) {
      background-size:contain;
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
      background-size: 60%;
    }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      background-size: 50%;
    }

    z-index:-1;
  }

}

.text-left {
  text-align:left;
}

.text-right {
  text-align:right;
}

/*** SWIPER ARROWS ***/

.swiper-pagination-bullet-active {
  background: #fff !important;
}

.swiper-button-next, .swiper-button-prev {
  color: var(--white) !important;
}

.TreehouseGallerySwiper {
  .swiper:hover > .swiper-button-next,
  .swiper:hover > .swiper-button-prev,
  {
    //display:flex !important;
    visibility: visible;
    opacity: 1;
  }

  .swiper-button-next, .swiper-button-prev {
    //display :none !important;
    color: #D7CBB0 !important;
    right: auto !important;
    background: #fff !important;
    width: 80px !important;
    height: 80px !important;
    top: 50% !important;
    transform: translate(0%, -50%) !important;
    visibility: hidden;
    opacity: 0;
    @media (max-width: 767.98px) {
      visibility: visible;
      opacity: 1;
    }
    -webkit-transition: all .4s;
    transition: all .4s;
    box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);

  }

  .swiper-button-prev {
    left: -20px!important;
  }

  .swiper-button-prev:hover {
    left:0px!important;
  }

  .swiper-button-next {
    right:-20px !important;
  }
  .swiper-button-next:hover {
    right:0px!important;
  }


}


/*** END SWIPER ***/

button {
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}


.banner {
    position:relative;
    z-index:1;
    //box-shadow: 0 14px 28px rgb(0 0 0 / 25%) inset, 0 10px 10px rgb(0 0 0 / 22%) inset;

    background-position: bottom;
    background-attachment: fixed;
    background-repeat:no-repeat;
    background-size:cover;
  color:#fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height:100vh;
  max-height:85vh;

  &.thin {
    height:unset;
    //min-height:50vh;
    padding:3rem 0;

    .bg {
      height:auto!important;
    }
  }

  .container {
    z-index:1;
  }

  h3 {
    font-family: "El Messiri", sans-serif;
    font-size: 3rem;
    font-weight: 800;

    @media (max-width: 767.98px) {
      font-size: 2rem;
    }

  }

  p {
    font-size:2rem;

    &.signature {
      font-family: "Babylonica", cursive;
      font-size: 6rem;
      margin-top: -30px;
      margin-bottom: -25px;
    }

    @media (max-width: 767.98px) {
      font-size: 1rem;
    }
  }


  h3, p {
    //text-shadow: 2px 2px 1px rgb(0 0 0 / 65%);

    strong,span {
      font-style:italic;
      font-weight:900;
      color:var(--clay);
    }
  }

  .reconnect {
    color:var(--winter)
  }

  .bg {
    height:100vh!important;
    object-fit: cover
  }


  &.bg-wheat {
    background-image: url('/assets/wheat.webp');
  }

  &.bg-concrete {
    background-image: url('/assets/concrete.webp');
  }

  &.bg-leaves {
    background-image: url('/assets/leaves.webp');
  }

  &.bg-dandelion {
    background-image: url('/assets/dandelion-bg.webp');
  }

  &.bg-plants {
    background-image: url('/assets/plants.webp');
  }

  &.bg-treebark {
    background-image: url('/assets/treebark.webp');
  }

  &.bg-treetrunkleaf {
    background-image: url('/assets/treetrunk-leaf.webp');
  }

  &.bg-ladybird {
    background-image: url('/assets/ladybird.webp');
  }

  &.bg-treeinleaves {
    background-image: url('/assets/tree-in-leaves.webp');
  }


  &:after {
    position: absolute;
    top:0;
    left:0;
    display:block;
    content: ' ';
    height:100%;
    width:100%;
    background: rgba(0,0,0,.55);
    z-index:0;
  }
}


.msg-alert {

  background: var(--ash);
  padding: 5px;
  margin-top: 5px;

  p {
    margin-bottom: 0;
    text-align: center;
    font-weight:800;
    color: var(--white) !important;
  }
}


.react-parallax-background-children {
  height:100%;
  width:100%;
}

.min-vh-50 {
  min-height: 50vh !important;
}

.vh-50 {
  height: 50vh !important;
}

.indicators {
  padding-left: 0 !important;
}

.react-slideshow-container+ul.indicators li {
  width: 10px !important;
  height: 10px!important;
  padding: 8px!important;
}

.react-slideshow-container+ul.indicators .each-slideshow-indicator:before {
  background:#fff !important;
  width: 10px !important;
  height: 10px!important;
}


.react-parallax:after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  content: " ";
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.25);
  z-index: 0;
}