.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    padding: 16px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);

    @media (min-width: 768px) {
        flex-direction: row;
        border-radius: 40px;
        padding: 8px;
    }
}

.section {
    width: 100%;
    padding: 12px;
    margin-bottom: 12px;

    @media (min-width: 768px) {
        flex: 1;
        padding: 8px 24px;
        margin-bottom: 0;
        border-right: 1px solid #e0e0e0;

        &:last-of-type {
            border-right: none;
        }
    }
}

.label {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 4px;
}

.input {
    width: 100%;
    border: none;
    font-size: 16px;
    padding: 0;
    outline: none;
    background: transparent;
    cursor: pointer;
}

.guestSelector {
    display: flex;
    align-items: center;
}

.select {
    width: 100%;
    border: none;
    font-size: 18px;
    padding: 0;
    margin-left: 8px;
    outline: none;
    background: transparent;
}

.button {
    width: 100%;
    padding: 12px 24px;
    background: #ff385c;
    color: white;
    border: none;
    border-radius: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease;

    &:hover {
        background: darken(#ff385c, 5%);
    }

    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }

    @media (min-width: 768px) {
        width: auto;
        margin-left: 16px;
    }
}

.buttonText {
    margin-left: 8px;
}

.datePickerWrapper {
    width: 100%;
}


.calendar {
    font-size: 16px !important;
    padding: 1rem !important;
    border: 1px solid #e0e0e0 !important;
    border-radius: 8px !important;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
    margin-top: 8px !important;
    
    :global {
        .react-datepicker__month-container {
            width: 300px !important;
        }
        
    
        .react-datepicker__header {
            background: white !important;
            border-bottom: 1px solid #e0e0e0 !important;
            padding: 1rem !important;
        }
        
        .react-datepicker__current-month {
            font-size: 18px !important;
            font-weight: 600 !important;
            margin-bottom: 1rem !important;
        }
        
        .react-datepicker__day-name {
            width: 34px !important;
            font-size: 14px !important;
            color: #666 !important;
        }
        
        .react-datepicker__day {
            width: 40px !important;
            height: 40px !important;
            line-height: 40px !important;
            font-size: 14px !important;
            margin: 0 !important;
            
            &:hover {
                background-color: var(--lilac) !important;
                border-radius: 50% !important;
            }
        }
        
        .react-datepicker__day--selected {
            background-color: var(--green-lighter) !important;
            border-radius: 50% !important;
            color: white !important;
        }
        
        .react-datepicker__day--in-range {
             background-color: var(--green-lighter) !important;
            color: white !important;
        }
        
        .react-datepicker__day--keyboard-selected {
            background-color: rgba(255, 56, 92, 0.1) !important;
            border-radius: 50% !important;
        }
    }
}

.popper {
    z-index: 1000;
}
