.container {
    width: 75%;
    margin-bottom: 4rem;
}
.header {
    padding: 4rem;
}

.accordion {
    margin-bottom: 4rem;
}