.searchForm {
  padding: 10px;
  margin-bottom: 4rem;
}

.title {
    margin-top: 2rem;
}

.cards {
  margin-bottom: 2rem;
}

.searchParams {
  margin-bottom: 2rem;
}

.partialAvailability {
  .availabilityNote {
    background-color: #fff3cd;
    border: 1px solid #ffeeba;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border-radius: 0.25rem;
    
    p {
      margin: 0;
      
      &.text-warning {
        color: #856404;
        font-weight: bold;
      }
    }
  }
}