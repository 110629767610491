
header {
  position:sticky;
  top:0;
  width:100%;
  z-index:3;
}

.tdNav {

  z-index: 3;
  position:absolute;
  top:0;
  left:0;
  width:100%;
  padding: 40px 50px;
  color: var(--white);
  transition: all 0.75s cubic-bezier(0.25, 0.8, 0.25, 1);
  display:block;
  height:140px;
  background:transparent;

  &.fixed {
    background: var(--ash);
    padding: 25px 50px;
    transition: all 0.75s cubic-bezier(0.25, 0.8, 0.25, 1);
    height:110px;
    box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);


    a {
      color:var(--black)
    }

    .logo {
      height: 75px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      top: 18px;

    }

  }




  .links a {
    @media (max-width: 767.98px) {
      display:none;
    }

    position:relative;
    color: var(--white);

    &:hover {
      color: var(--white);
    }

    &:before {
        background-color: var(--white);
        content: '';
        display: block;
        position: absolute;
        bottom: 2px;
        left: 0;
        height: 1px;
        width: 100%;
        transform-origin: right top;
        transform: scale(0,1);
        transition: color .1s,transform .2s ease-out;
    }

    &:hover:before {
      transform-origin: left top;
      transform: scale(1, 1);
    }
  }

  a {
    color: var(--white);

  }


  .logo {
    transition: all 0.75s cubic-bezier(0.25, 0.8, 0.25, 1);
    height: 166px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: 34px;


    @media (max-height: 820px),(max-width: 767.98px) {
      height: 75px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      top: 34px;
    }

  }

  .btnBook {
    float:right;

    @media (max-width: 767.98px) {
      display:none;
    }

  }

}

.Hamburger {
  position: fixed;
  height: 27px;
  width: 35px;
  cursor: pointer;
  z-index: 1000;
  transition: opacity 0.25s ease;
  top: 40px;
  left: 50px;

  &:hover {
    opacity: .7;
  }

  &.open {

    span {

      transform: translateY(11px) translateX(0) rotate(45deg);

      &:nth-of-type(2) {
        opacity: 0;
      }

      &:nth-of-type(3) {
        transform: translateY(-11px) translateX(0) rotate(-45deg);
      }

    }

  }

  span {
    background: #fff;
    border: none;
    height: 5px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition:  all .35s ease;
    cursor: pointer;

    &:nth-of-type(2) {
      top: 11px;
    }

    &:nth-of-type(3) {
      top: 22px;
    }
  }

}

menu.MenuOverlay {
  position: fixed;
  background: var(--black);
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity .35s, visibility .35s, height .35s;
  overflow: hidden;
  margin:0;
  padding:0;
  z-index:999;

  &.menuOpen {
    opacity: .95;
    visibility: visible;
    height: 100vh;

    li {
      animation: fadeInRight .5s ease forwards;
      animation-delay: .35s;

      &:nth-of-type(2) {
        animation-delay: .4s;
      }
      &:nth-of-type(3) {
        animation-delay: .45s;
      }
      &:nth-of-type(4) {
        animation-delay: .50s;
      }
    }
  }
  nav {
    position: relative;
    height: 70%;
    top: 50%;
    transform: translateY(-50%);
    font-size: 50px;
    //font-family: 'Varela Round', serif;
    font-weight: 400;
    text-align: center;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    height: 100%;

    li {
      display: block;
      height: 25%;
      height: calc(100% / 5);
      min-height: 50px;
      position: relative;
      opacity: 0;


      a {
        display: block;
        position: relative;
        color: var(--white);
        text-decoration: none;
        overflow: hidden;
        font-family: "El Messiri", sans-serif;
        font-size:2.25rem;

        &:hover {
          color:#fff;
        }

        &:hover:after,
        &:focus:after,
        &:active:after {
          width: 100%;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 0%;
          transform: translateX(-50%);
          height: 3px;
          background: var(--white);
          transition: .35s;
        }
      }
    }
  }

}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}


// Link styling for Founder and CEO quote section
.aboutLink {
  text-decoration: none;
  color: inherit;
}

.aboutLink:hover {
  color: var(--winter);
  text-decoration: underline;
}