.AboutSection
{
  padding:0;
  overflow:hidden;

  .textContainer
  {
    padding:35px 20px 50px 20px;
    @media (max-width: 767.98px) {
      //min-height:100vh;
    }

    .Quote {
      font-size:30px;
      line-height: 1.13;
      padding-bottom:30px;
      cursor: grab;
      font-weight:700;
      margin:0 2rem;

      h4 {
        font-size:3rem;
        @media (max-width: 767.98px) {
          font-size:1.5rem;
        }
      }

      p {
        font-size:1.5rem;
        @media (max-width: 767.98px) {
          font-size:1rem;
          font-weight:normal;
        }
      }

      em {
        //font-family: "El Messiri", sans-serif;
        font-size: 4rem;
        @media (max-width: 767.98px) {
          display:none;
        }
      }

      em:first-child {
        padding-right: 20px;
      }

      em:last-child {
        padding-left:0px;
      }
    }

  }

  .videoContainer {
    min-height:100vh;
    @media (max-width: 991.98px) {
      min-height:unset;
    }
    @media (min-width: 768px) and (max-width: 991.98px) {

    }
    @media (min-width: 992px) and (max-width: 1199.98px) {

    }

    .video, .videoRight {
      object-fit: cover;
      object-position: left;
      height:100vh;
      width:100%;
      position:absolute;
    }

    .videoRight {
      object-position: right;
    }

    .video
    {

      @media (max-width: 767.98px) {
        position: relative;
        height:unset;
      }
      @media (min-width: 768px) and (max-width: 991.98px) {
        position: relative;
        height:unset;
      }
      @media (min-width: 992px) and (max-width: 1199.98px) {

      }
      /*@media (min-width: 768px) and (max-width: 991.98px) {
        background-size: 60%;
      }
      @media (min-width: 992px) and (max-width: 1199.98px) {
        background-size: 50%;
      }*/

    }

  }



  .CopyContainer {
    /*position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;*/

    .Copy {
      /*align-content: center;
      align-items: center;
      justify-content: center;*/
    }

  }





}

.Banner {
  position:relative;
  z-index:1;
  box-shadow: 0 14px 28px rgb(0 0 0 / 25%) inset, 0 10px 10px rgb(0 0 0 / 22%) inset;
  background-image: url('/assets/wheat.webp');
  background-position: bottom;
  background-attachment: fixed;
  background-repeat:no-repeat;
  background-size:cover;

  h3 {
    text-shadow: 2px 2px 1px rgb(0 0 0 / 65%);
  }

  /*  &:after {

      background-image: url('/assets/tree-silhouettes.webp');
      background-position: bottom -11rem center;
      background-repeat: no-repeat;
      z-index: -1;
      display: block;
      content: " ";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: 100%;
    }*/

}

.FactContainer {

  margin:3rem 0;

  .Fact {

    h2 {
      font-size:4rem;
      letter-spacing: -1px;
      margin-bottom:-5px;
      opacity:.99;
    }

    .FactTitle {
      font-size: 1.2rem;
      text-transform: capitalize;
      font-weight: 600;
      margin-bottom: 12px;
    }

    p {
      font-size:1rem;
    }

  }


}

