@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

  .TreeHouseCard {
    height:750px;
    max-height:77vh;
    //width:75%;
    //width: 500px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
    position:relative;
    //cursor:pointer;

    &:hover {
      box-shadow: 0 24px 28px rgb(0 0 0 / 25%), 0 20px 20px rgb(0 0 0 / 22%);
    }

    &:hover .Img {

      filter: none;
      -webkit-transform: scale(1.09, 1.09);
      transform: scale(1.09, 1.09);
      -webkit-filter: brightness(100%);
      filter:brightness(100%);
      -webkit-transition: all 1s ease;
      -moz-transition: all 1s ease;
      -o-transition: all 1s ease;
      -ms-transition: all 1s ease;
      transition: all 1s ease;
    }

    .Inner {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      height:100%;
    }

    .ImgContainer {
      height: 60%;
      width: 100%;
      z-index: 1;
      /*outline: 1px solid white;
      outline-offset: -2rem;*/
      position: relative;
      display: flex;
      overflow:hidden;

      @media (max-height:850px) {
        height:50%;
      }


      /*      @media (max-width: 767.98px) {
              height: 50%;
            }*/

    }


    .Img {
      -webkit-transition: all .5s;
      transition: all .5s;
      filter: brightness(80%);
      height:100%;
      width:100%;
      display:flex;
      background-size: cover;
      background-position: center;

      @media (max-width: 767.98px) {
        filter: brightness(100%)
      }

    }

    .Info {
      position:relative;
      outline: 1px solid white;
      padding: 4rem;
      outline-offset: -2rem;
      width:100%;
      height:40%;
      background-color: var(--green);
      color: var(--white);
      z-index: 1;
      overflow:hidden;

      @media (max-width: 767.98px) {
        padding:2rem;
        outline-offset: -1rem
      }

      @media (max-height:850px) {
        height:50%;
      }

      h4 {
        font-family: 'Urbanist', sans-serif;
        text-transform:uppercase;
        font-weight:700;
        font-size:2rem;

        a,a:hover {
          color:#fff;
          text-decoration:none;
        }

      }

      &:after {
        z-index:-1;
        display:block;
        content: ' ';
        position:absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        //opacity: 0.5;
        background-image: url('/assets/TreeDwellers_Silhouettes-dandelions.webp');
        background-position: bottom -3rem right -2rem;
        background-repeat: no-repeat;
        background-size: 50%;

        @media (max-width: 767.98px) {
          background-size: 80%;
        }

      }


      .attributes {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        font-size:1.4rem;

        @media (max-width: 767.98px) {
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
        }

        > div {

          svg {

          }

          span {
            text-transform: capitalize;
          }

        }

      }

      .actions {

        position:absolute;
        bottom:0;
        width: 100%;
        left: 0;
        padding: 0 4rem 3rem;
        display: flex;
        align-items: center;
        justify-content: space-around;

        @media (max-width: 767.98px) {
          padding: 0 2rem 2rem;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }


        .price {
          display: flex;
          flex: 1;
          font-weight:700;
          font-size: 1.5rem;
        }

        .link {
          display: flex;
          flex: 1;
          @media (max-width: 767.98px) {
            width:100%;
          }

          .btn {
            padding: 0;
            border-radius: 15px;
            width: 100%;
          }

        }

      }

    }

    //@media (min-width: 767.98px) {
    @include media-breakpoint-up(md) {
      &.TreeHouseCardList {
        height: 390px;

        .Inner {
          flex-direction: row;
        }

        &:hover .Img {
          filter: none;
          -webkit-transform: unset;
          transform: unset;
          -webkit-filter: unset;
          -webkit-transition: all 1s ease;
          -moz-transition: all 1s ease;
          -o-transition: all 1s ease;
          -ms-transition: all 1s ease;
          transition: all 1s ease;
        }

        .ImgContainer {
          height: 100%;



        }

        .Info {
          height: 100%;
          @include media-breakpoint-down(xl) {
            padding:3rem;
          }

          h4 {
            text-align: center;
          }
        }

        .attributes {
          text-align: center;

          @include media-breakpoint-down(xl) {
            margin-top:2rem;
          }

          @include media-breakpoint-down(lg) {
            margin-top:3rem;
          }

          > div {
            text-align: center;
            margin: 0 auto;
            height: 80px;
            position: relative;
            width: 100%;
            margin-bottom: 20px;

            svg {
              margin: 0 auto !important;
              display: block;
            }

            span {
              position: absolute;
              bottom: 0;
              width: 100%;
              display: block;
            }

          }
        }

        .USP {
          @include media-breakpoint-down(xl) {
            font-size: 1rem;
          }
          font-size:1.2rem;
          font-style: italic;
          text-align: center;
          flex-direction: column;
        }

      }
    }

  }
