.Footer {

  background-image: url('/assets/bg-leafs.webp');
  background-attachment: initial;
  background-position: center;
  background-repeat: no-repeat;
  /* background-size: 120% 300%; */
  color: var(--white);

  h4 {
    font-family: 'Urbanist', sans-serif;
    font-size:2rem;
    font-weight:800;
  }

  .FooterRow {

    >div {

      a,a:hover {
        color:#fff;
      }

      &:nth-of-type(1) {
        text-align: left;

        @media (max-width: 767.98px) {
          text-align: center;
          margin-bottom:2rem;
        }

      }

      &:nth-of-type(2) {
        text-align: center;
        @media (max-width: 767.98px) {
          text-align: center;
          margin-bottom:2rem;
        }
      }

      &:nth-of-type(3) {
        text-align:right;
        @media (max-width: 767.98px) {
          text-align: center;
          margin-bottom:2rem;
        }
      }

    }

  }
}
.SocialSection
{

  h5 {
    font-family: "Urbanist", sans-serif;
    font-weight:800;
  }

  h4 {
    font-size:3rem;
  }

  .SocialIcons{
    align-items: flex-start;
    bottom: 0;
    display: flex;
    justify-content: center;
    justify-content: space-around;
    left: 0;
    padding: 24px 10%;
    width: 100%;
    flex-wrap: wrap;

    div {
      margin:1rem 0;
    }

    a {
      @media (max-width: 767.98px) {
        flex: 1 0 50%;
      }
    }

  }



  a,a:hover {
    color:var(--bodycolor);
    text-transform: uppercase;
    text-decoration: none;

  }
}