.PageHero {
  height:80vh;
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  position: relative;
  background-attachment: fixed;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: cover;
  //padding: 2.5rem 0 4rem;
  color: #fff;
  text-align: center;
  //margin-bottom:3rem;

  .content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index:1;

    .contentMain {

      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .headline {
        width: 800px;
        max-width: 90vw;
      }

    }

    .contentAction {
      //margin-bottom: 56px;
      display: flex;
      align-items: flex-start;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: space-around;
      padding: 24px 10%;

      a,a:hover {
        color:var(--white)
      }

      .go {
        flex: 1;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;


        .scroll {

          --size: 0.9;
          --color: rgba(255, 255, 255, 0.75);
          --opacity: 1;
          --width: calc(26px * var(--size));
          --height: calc(var(--width) * 2);
          --ballSize: calc(var(--width) / 4);
          border: none;
          display: block;
          background: transparent;
          cursor:pointer;
          opacity: var(--opacity);
          z-index: 1001;
          transition: opacity 0.165s ease-out, transform 0.165s ease-out;
          position: relative; }
        .scroll.hidden {
          transform: translate3d(0, 25px, 0);
          opacity: 0; }
        .scroll span {
          display: block;
          width: var(--width);
          height: var(--height);
          border: calc(2px * var(--size)) solid var(--color);
          border-radius: calc(var(--width) / 2); }
        .scroll span:before {
          content: ' ';
          width: var(--ballSize);
          height: var(--ballSize);
          border-radius: 50%;
          background-color: var(--color);
          position: absolute;
          top: calc(var(--height) * 0.1);
          left: calc(50% - var(--ballSize) / 2);
          animation: scroll 1s infinite; }
        .scroll span:after {
          content: ' ';
          position: absolute;
          display: none;
          color: var(--color);
          font-family: 'Source Code Pro', monospace;
          bottom: calc(-24px * var(--size));
          line-height: calc(20px * var(--size));
          font-size: calc(12px * var(--size));
          left: calc(50% - calc(72px * var(--size)) / 2);
          width: calc(72px * var(--size));
          text-align: center;
          padding-top: calc(12px * var(--size)); }
        @media all and (max-width: 3080px) {
          .scroll {
            --size: 1;
            --width: calc(26px * var(--size));
            --height: calc(var(--width) * 2); } }

        @keyframes scroll {
          0% {
            transform: translate(0, 0);
            opacity: 0; }
          40% {
            opacity: 1; }
          80% {
            transform: translate(0, calc(var(--height) * 0.7));
            opacity: 0; }
          100% {
            opacity: 0; } }


      }
    }



  }

  @media all and (max-width: 840px) {
    h1 {
      font-size: 1.5rem;
    }
  }
}