:root {
    --ash:#5a564f;
    --grey:#847f7e;
    --winter:#c2cad2;
    --green:#969c7f;
    --green-lighter:#7f9c83;
    --rose:#b58a8a;
    --rose-lighter:#c35555;
    --sandstone:#d7cbb0;
    --white:#fff;
    --black:#272727;
    --lilac:#7f8090;
    --clay:#b79876;
    --bodycolor:#272727;
    --dark:#11171B;
    --dark-dandelion: #59564E;
  }

.EmailSignupForm {
    
    
    max-width: 100vw;
    overflow:hidden;

    .bg {
        background-color: var(--white);
        width: 100vw;
    }

    p,
    h2 {
        text-align: center;
        padding: 10px;
    }

    .formGroup {
        padding: 20px;
        display: flex;
        justify-content: center;
        max-width: 100vw;
    }

    .form {
        width: max-content;
        padding: 10px;
        background-color: none;
    }

    .form input {
        padding: 10px;
        margin-top: 12px;
        width: 95%;
        border-radius: 5px;
        border: 1px solid var(--ash);
    }

    button {
        padding:10px 70px;
        margin-top: 20px;
        border-radius:30px;
        font-size:1.2rem;
        

        &:hover {
            //font-weight:700;
            //box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
            box-shadow: 0px 3px 4px rgb(0 0 0 / 25%), 5px 6px 10px rgb(0 0 0 / 22%);
        }
        
        color: var(--white);
        background-color: var(--lilac);
        border-color: var(--lilac);
        
        &:hover {
            color: var(--black);
            background-color: var(--white);
            border-color: var(--white);
          }
      
          &:active {
            color: #fff;
            background-color: var(--white);
            border-color: var(--white);
          }
      
          &:focus {
            box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 50%);
          }
    }
}