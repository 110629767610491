.MeetTheTeamCard {

  //width: 300px;
  //height: 900px;
  width: 100%;
  perspective: 500px;
  margin-bottom:3rem;

  .Content {
    //position: absolute;
    width: 100%;
    //height: 100%;
    box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);

    transition: transform 1s;
    transform-style: preserve-3d;
  }

/*  &:hover .Content {
    transform: rotateY( 180deg ) ;
    transition: transform 0.5s;
  }*/

  &:hover img.ImgMain {
      opacity:0;
  }

  .Img {
    background-position: center;
    background-size: cover;
    width:100%;
    height: 600px;
    //filter: grayscale(1);
    //cursor:pointer;

    img {
      position:absolute;
      left:0;
      -webkit-transition: opacity 1s ease-in-out;
      -moz-transition: opacity 1s ease-in-out;
      -o-transition: opacity 1s ease-in-out;
      transition: opacity 1s ease-in-out;
      height: 600px;
      width: 100%;
      object-fit: cover;
      top: 0;

      &.ImgMain {
        filter:grayscale(1)
      }

    }



  }

  .Bio {
    position:relative;
    //height:44%;
    background-color: var(--green);
    padding: 2.5rem 2rem;
    outline: 1px solid white;
    //padding: 4rem;
    outline-offset: -1.5rem;

    .Name
    {
      font-family:"El Messiri", sans-serif;
      font-size:2rem;
    }

    .JobTitle
    {
      font-size:1.5rem;
    }

    .Socials {
      color:#fff;
      //position:absolute;
      bottom:2rem;
      left: 0;
      align-items: flex-start;

      display: flex;
      justify-content: space-around;
      width: 100%;


      div {
        margin:1rem 0;
      }

      a,a:hover {
        color:#fff;
      }
    }

  }

  .Front,
  .Back {
    //position: absolute;
    height: 100%;
    width: 100%;
    text-align: center;
    //font-size: 60px;
    border-radius: 5px;
    backface-visibility: hidden;
    color:#fff;
  }

  .Back {
    transform: rotateY( 180deg );
  }

}