
.AboutHeroVideo {


  position: relative;
  left: 0;
  width: 100%;
  background: rgb(17, 17, 17);
  overflow:hidden;

  .videoContainer {
    position: absolute;
    top: 0px;
    width: 100vw;
    height: 100%;
    z-index: 0;


    .video {
      object-fit: cover;
      height:100%;
      width:100%;
    }

    .overlay {
      width: 100%;
      height: 100%;
      background: linear-gradient(to top, rgb(0 0 0 / 0%), rgb(0 0 0 / 35%));
      position: absolute;
      z-index: 1;
      top: 0;
    }

  }





    .contentMain {

      //margin-top: 50vh;
      position: relative;
      z-index: 1;
      opacity: 1;
      color:#fff;
      height:100vh;

      .headline {
        margin: 0;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;

        h2 {
          font-family: "El Messiri", sans-serif;
          font-size: 5rem;
          font-weight: 800;
        }

        h3 {
          font-family: "Urbanist", sans-serif;
          font-size: 2rem;
        }

        p {
          font-size: 1.5rem;
        }

        span {
          font-style: italic;
          font-weight: 900;
          color: var(--clay);
        }

      }

    }

  .contentAction {
    //margin-bottom: 56px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 24px 10%;
    z-index:1;

    a,a:hover {
      color:var(--white)
    }

    .go {
      flex: 1;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;


      .scroll {

        --size: 0.9;
        --color: rgba(255, 255, 255, 0.75);
        --opacity: 1;
        --width: calc(26px * var(--size));
        --height: calc(var(--width) * 2);
        --ballSize: calc(var(--width) / 4);
        border: none;
        display: block;
        background: transparent;
        cursor:pointer;
        opacity: var(--opacity);
        z-index: 1001;
        transition: opacity 0.165s ease-out, transform 0.165s ease-out;
        position: relative; }
      .scroll.hidden {
        transform: translate3d(0, 25px, 0);
        opacity: 0; }
      .scroll span {
        display: block;
        width: var(--width);
        height: var(--height);
        border: calc(2px * var(--size)) solid var(--color);
        border-radius: calc(var(--width) / 2); }
      .scroll span:before {
        content: ' ';
        width: var(--ballSize);
        height: var(--ballSize);
        border-radius: 50%;
        background-color: var(--color);
        position: absolute;
        top: calc(var(--height) * 0.1);
        left: calc(50% - var(--ballSize) / 2);
        animation: scroll 1s infinite; }
      .scroll span:after {
        content: ' ';
        position: absolute;
        display: none;
        color: var(--color);
        font-family: 'Source Code Pro', monospace;
        bottom: calc(-24px * var(--size));
        line-height: calc(20px * var(--size));
        font-size: calc(12px * var(--size));
        left: calc(50% - calc(72px * var(--size)) / 2);
        width: calc(72px * var(--size));
        text-align: center;
        padding-top: calc(12px * var(--size)); }
      @media all and (max-width: 3080px) {
        .scroll {
          --size: 1;
          --width: calc(26px * var(--size));
          --height: calc(var(--width) * 2); } }

      @keyframes scroll {
        0% {
          transform: translate(0, 0);
          opacity: 0; }
        40% {
          opacity: 1; }
        80% {
          transform: translate(0, calc(var(--height) * 0.7));
          opacity: 0; }
        100% {
          opacity: 0; } }


    }
  }



  @keyframes bounce{20%,53%,80%,from,to{animation-timing-function:cubic-bezier(.215,.61,.355,1);transform:translate3d(0,0,0)}40%,43%{animation-timing-function:cubic-bezier(.755,.050,.855,.060);transform:translate3d(0,-30px,0)}70%{animation-timing-function:cubic-bezier(.755,.050,.855,.060);transform:translate3d(0,-15px,0)}90%{transform:translate3d(0,-4px,0)}}.bounce{animation-name:bounce;transform-origin:center bottom}

}

.VisionMission {
  video {
    object-fit: cover;
    height:100%;
    width:100%;
  }
}

.Quote {
  font-size:30px;
  line-height: 1.13;
  padding-bottom:30px;
  cursor: grab;
  font-weight:700;
  margin:0 2rem;

  h4 {
    font-size:3rem;
    font-weight:800;
  }

  p {
    font-size:1.5rem;
  }

  em {
    //font-family: "El Messiri", sans-serif;
    font-size: 4rem;
  }

  em:first-child {
    padding-right: 20px;
  }

  em:last-child {
    padding-left:0px;
  }
}

.AboutHero {
  padding-top:200px;
}